<template>
  <div>
    <new-transaction :IsNewTransactionActive="NewTransactionActive" @closePopup="toggleNewTransactionPopup">
    </new-transaction>
    <transfer-money :IsNewTransferMoneyActive="TransferMoney" @closePopup="toggleTransferMoneyPopup"></transfer-money>
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">

        <vs-card>
          <div slot="header">
            <h3>
              {{AccountData.full_name+' '+$t('Account Details')}}
            </h3>
          </div>
          <vs-row vs-justify="center">
            <vs-col class="vx-col sm:w-3/12 mr-5">
              <vx-card :title="$t('Total Outcome')">
                <h5> {{ new Intl.NumberFormat('tr-TR',{
                  minimumFractionDigits: 2,}).format(totalOutcome) }} TL</h5>
              </vx-card>
            </vs-col>
            <vs-col class="vx-col sm:w-3/12 mr-5">
              <vx-card :title="$t('Total Income')">
                <h5> {{ new Intl.NumberFormat('tr-TR',{
                  minimumFractionDigits: 2,}).format(totalIncome) }} TL </h5>
              </vx-card>
            </vs-col>
            <vs-col class="vx-col sm:w-3/12">
              <vx-card :title="$t('Total Balance')">
                <h5>{{ new Intl.NumberFormat('tr-TR',{
                  minimumFractionDigits: 2,}).format((totalIncome-totalOutcome)) }} TL </h5>
              </vx-card>
            </vs-col>
          </vs-row>
          <vs-row vs-justify="center">
            <vs-col class="mt-6 pt-6">
              <vs-row vs-justify="center">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
                  <vs-button @click="activePrompt=true" color="warning" type="gradient" class="mr-3">
                    <font-awesome-icon class="mr-4" :icon="['fas', 'print']" />
                    {{$t('Export Selected')}}
                  </vs-button>
                </vs-col>
                <vs-prompt :title="$t('Export To Excel')" class="export-options" @cancle="clearFields"
                  @accept="exportToExcel" :accept-text="$t('Export')" :cancel-text="$t('Cancel')" @close="clearFields"
                  :active.sync="activePrompt">
                  <vs-input v-model="fileName" :placeholder="$t('Enter File Name..')" class="w-full" />
                  <v-select v-model="selectedFormat" :options="formats" class="my-4" />
                  <div class="flex mt-4">
                    <span class="mr-2">{{$t('Cell Auto Width')}}</span>
                    <vs-switch v-model="cellAutoWidth">{{$t('Cell Auto Width')}}</vs-switch>
                  </div>
                </vs-prompt>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
                  <vs-button @click="TransferMoney=true" color="success" type="gradient">
                    <font-awesome-icon class="mr-4" :icon="['fas', 'exchange-alt']" />
                    {{$t('Transfer Money')}}
                  </vs-button>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="8">
                  <vs-button @click="NewTransactionActive=true" color="success" type="gradient">
                    <font-awesome-icon class="mr-2" :icon="['fas', 'plus']" />
                    {{$t('New')}}
                  </vs-button>
                </vs-col>
              </vs-row>
            </vs-col>


            <vs-col class="mt-6 pt-6">

              <div class="vx-row">
                <div v-if="queryStrings" class="vx-col sm:w-1/12">
                  <vs-button @click="cleaner()" class="w-full mt-4">
                    <font-awesome-icon :icon="['fas', 'times']" />
                  </vs-button>
                </div>
                <div class="vx-col md:w-2/12 w-full">
                  <vs-input class="w-full" :label="$t('Description')" v-model="filter.description" />
                </div>

                <div class="vx-col md:w-2/12 w-full">
                  <my-select autocomplete class="selectExample " style="width:99%" :label="$t('Category')" @change="checkSubCategory"
                    v-model="filter.category">
                    <vs-select-item key="0hhh" :text="$t('Select Category')" />
                    <vs-select-item :key="index" :value="item.name" :text="item.name"
                      v-for="(item,index) in categories" />
                  </my-select>
                </div>

                <div v-if="subcategories.length>0 && filter.category" class="vx-col sm:w-2/12 w-full">
                  <my-select autocomplete :placeholder="$t('Select Sub Category')" style="width:99%" class="selectExample mt-5"
                    v-model="filter.sub_category">
                    <vs-select-item key="0f" :text="$t('Select Sub Category')" />
                    <vs-select-item :key="index" :value="item.name" :text="item.name"
                      v-for="(item,index) in subcategories" />
                  </my-select>
                </div>
                <div class="vx-col sm:w-2/12 ">
                  <vs-input id="calendar" type="date" :max="filter.issue_date_end" style="width:99%"
                    :label="$t('Transaction Date - Start')" v-model="filter.issue_date" />
                </div>
                <div class="vx-col sm:w-2/12 ">
                  <vs-input id="calendar" type="date" :min="filter.issue_date" style="width:99%"
                    :label="$t('Transaction Date - End')" v-model="filter.issue_date_end" />
                </div>
              </div>


              <vs-pagination-table model_type="account" :transaction="$route.params.accountId" stripe :data="details">
                <template slot-scope="{ data }">
                  <template>

                    <vs-th v-if="false">{{$t('No')}}</vs-th>
                    <vs-th>{{$t('Transaction Date')}}</vs-th>
                    <vs-th>{{$t('Category')}}</vs-th>

                    <vs-th>{{$t('Income')}}</vs-th>
                    <vs-th>{{$t('Outcome')}}</vs-th>
                    <vs-th>{{$t('Balance')}}</vs-th>
                    <vs-th>{{$t('Beneficiary')}}</vs-th>
                    <vs-th>{{$t('Affected')}}</vs-th>
                    <vs-th>{{$t('Description')}}</vs-th>
                    <vs-th>{{$t('Transaction Owner')}}</vs-th>
                    <vs-th>{{$t('Options')}}</vs-th>
                  </template>
                  <template>
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td v-if="false">
                        <vs-chip transparent color="warning">
                          {{ tr.no }}
                        </vs-chip>
                      </vs-td>
                      <vs-td>
                        <vs-chip transparent color="primary" v-if="tr.issue_date">
                          <vs-avatar color="rgb(var(--vs-primary))" icon="event_note" />
                          <vx-tooltip transparent color="primary" :text="tr.issue_date">
                            {{ tr.issue_date }}
                          </vx-tooltip>
                        </vs-chip>
                      </vs-td>
                      <vs-td>
                        <span v-if="tr.child">
                          {{tr.child.name}} /
                        </span>
                        {{ tr.category }}
                      </vs-td>

                      <vs-td v-bind:style=" tr.income ? 'min-width:150px' : '' ">
                        <font-awesome-icon color="rgb(var(--vs-success))" :icon="['fas', 'arrow-down']"
                          v-if="tr.income" />
                        {{ tr.income ? tr.income+' TL' : '-' }}
                      </vs-td>
                      <vs-td v-bind:style=" tr.outcome ? 'min-width:150px' : '' ">
                        <font-awesome-icon color="rgb(var(--vs-danger))" :icon="['fas', 'arrow-up']"
                          v-if="tr.outcome" />
                        {{ tr.outcome ? tr.outcome+' TL' : '-' }}
                      </vs-td>
                      <vs-td v-bind:style=" tr.balance ? 'min-width:150px' : '' ">
                        <font-awesome-icon color="rgb(var(--vs-warning))" :icon="['fas', 'exchange-alt']"
                          v-if="tr.balance" />
                        {{ tr.balance+' TL' }}
                      </vs-td>
                      <vs-td>
                        <a v-if="tr.beneficiary && tr.beneficiary_type==='account'" :href="$router.resolve({name: 'AccountTransactions',
                        params: { accountId: tr.beneficiary_id }}).href">
                          <vs-chip transparent color="success">
                            <vs-avatar color="rgb(var(--vs-success))"
                              :icon="tr.beneficiary_type==='account'?'supervisor_account':'layers'" />
                            {{ tr.beneficiary }}
                          </vs-chip>
                        </a>
                        <a v-if="tr.beneficiary && tr.beneficiary_type==='cashier'" :href="$router.resolve({name: 'cashier-details',
                        params: { cashierId: tr.beneficiary_id }}).href">
                          <vs-chip transparent color="danger">
                            <vs-avatar color="rgb(var(--vs-danger))"
                              :icon="tr.beneficiary_type==='account'?'supervisor_account':'layers'" />
                            {{ tr.beneficiary }}
                          </vs-chip>
                        </a>
                        <vs-chip transparent color="warning" v-if="!tr.beneficiary">
                          -
                        </vs-chip>
                      </vs-td>
                      <vs-td>
                        <a v-if="tr.affected && tr.affected_type==='account'" :href="$router.resolve({name: 'AccountTransactions',
                        params: { accountId: tr.affected_id }}).href">
                          <vs-chip transparent color="success">
                            <vs-avatar color="rgb(var(--vs-success))"
                              :icon="tr.affected_type==='account'?'supervisor_account':'layers'" />
                            {{ tr.affected }}
                          </vs-chip>
                        </a>
                        <a v-if="tr.affected && tr.affected_type==='cashier'" :href="$router.resolve({name: 'cashier-details',
                        params: { cashierId: tr.affected_id }}).href">
                          <vs-chip transparent color="success">
                            <vs-avatar color="rgb(var(--vs-success))"
                              :icon="tr.affected_type==='account'?'supervisor_account':'layers'" />
                            {{ tr.affected }}
                          </vs-chip>
                        </a>
                        <vs-chip transparent color="warning" v-if="!tr.affected">
                          -
                        </vs-chip>
                      </vs-td>
                      <vs-td >
                        <vx-tooltip v-if="tr.description" :text="tr.description">
                        {{ tr.description.length>20 ? tr.description.substr(0,20)+'...' : tr.description }}
                     </vx-tooltip>
                      </vs-td>
                      <vs-td v-if="tr.user">
                        {{ tr.user.name }}
                      </vs-td>
                      <vs-td>
                        <font-awesome-icon @click="editDescription(tr)" class="cursor" color="rgb(var(--vs-success))"
                          :icon="['fas', 'edit']" />
                      </vs-td>
                    </vs-tr>
                  </template>
                </template>
              </vs-pagination-table>
            </vs-col>
          </vs-row>

        </vs-card>
        <vs-prompt :title="$t('Açıklamayı Düzenle')" :accept-text="$t('Update')" @accept="editDescription(editData.id)"
          :cancel-text="$t('Cancel')" class="export-options" :active.sync="editPopup">
          <vs-textarea v-model="editData.description" class="my-4" />

        </vs-prompt>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import NewTransaction from './components/NewTransaction.vue'
import TransferMoney from './components/TransferMoney.vue'
import vSelect from 'vue-select'
import MySelect from '../../../components/MySelect'
import axios from '../../../axios'

export default {

  /* eslint-disable */
    components: {
      TransferMoney,
      NewTransaction,
      vSelect,
      MySelect
    },
    data: () => ({
      AccountId: '',
      NewTransactionActive: false,
      TransferMoney: false,
      search: '',
      //
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      headerTitle: ['No', 'Issue Date', 'Category', 'Incomming', 'Outcomming', 'Balance', 'Beneficiary', 'Affected',
        'Description'
      ],
      headerVal: ['no', 'issue_date', 'category', 'income', 'outcome', 'balance', 'beneficiary', 'affected', 'description'],
      activePrompt: false,
      filter: {
        description: null,
        category: null,
        sub_category: null,
        issue_date: new Date(new Date().getFullYear(), new Date().getMonth(), 2).toJSON().slice(0, 10).replace(/-/g,
            '-'),
          issue_date_end: new Date().toJSON().slice(0, 10).replace(/-/g, '-')
      },
      queryTimer: null,
      subcategories: [],
      sub_id: null,
      selectedCat: null,
      uniqueCategories: [],
      editPopup: false,
      editData: {},
      allDatas:[]
    }),

    watch: {
      'categories'(val) {
        this.checkSubCategory()
      }
    },

    computed: {

      totalOutcome() {
        let total = this.allDatas.map((x) => {
          let val = x.outcome.toString().replace(',', '')
          return parseFloat(val)
        })
        if (total.length > 0) {
          return total.reduce((a, b) => a + b)
        }
        return 0
      },
      totalIncome() {
        let total = this.allDatas.map((x) => {
          let val = x.income.toString().replace(',', '')
          return parseFloat(val)
        })
        if (total.length > 0) {
          return total.reduce((a, b) => a + b)
        }
        return 0
      },

      categories() {
        let val = this.$store.getters["transactionCategory/data"]
        val = val.filter((obj) => {
          return this.uniqueCategories.find(x => x === obj.name)
        })
        return val
      },
      details() {
        return this.$store.getters['account/transactions']
      },
      AccountData() {
        return this.$store.getters["account/account"]
      },
      queryStrings() {
        let start = null
        let queryStrings = ''
        if (this.filter.description) {
          start = !start ? '?' : '&'
          queryStrings += start + 'description=' + this.filter.description
        }
        if (this.filter.category) {
          start = !start ? '?' : '&'
          queryStrings += start + 'category=' + this.filter.category
        }
        if (this.filter.sub_category) {
          start = !start ? '?' : '&'
          queryStrings += start + 'sub_category=' + this.filter.sub_category
        }
        if (this.filter.issue_date) {
          start = !start ? '?' : '&'
          queryStrings += start + 'issue_date=' + this.filter.issue_date
        }
        if (this.filter.issue_date_end) {
          start = !start ? '?' : '&'
          queryStrings += start + 'issue_date_end=' + this.filter.issue_date_end
        }
        clearTimeout(this.queryTimer)
        this.queryTimer = setTimeout(() => {
          this.$router.push(queryStrings)
          this.getData()
        }, 500)
        return queryStrings
      }
    },


    methods: {
      editDescription(data) {
        if (typeof data === 'object') {
          this.editPopup = true
          this.editData = {...data}
        } else {
          axios.post('transaction/edit/'+data,{
            description:this.editData.description
          }).then(response => {
            this.getData()
          })
        }
      },
      checkSubCategory() {
        if (this.filter.category && this.categories.length > 0) {
          this.subcategories = this.categories.find(x => x.name === this.filter.category).parents
          this.sub_id = null
        }
      },
      cleaner() {
        Object.keys(this.filter).forEach(index => {
          this.$set(this.filter, index, null)
        })
      },

      allData() {
        axios.get('/account/transactions/' + this.$route.params.accountId + '?nopaginate=true&'+
        this.queryStrings.slice(1)).then(response => {
          this.allDatas = response.data.data
        });
      },

      noFilterData() {
        axios.get('/account/transactions/' + this.$route.params.accountId).then(response => {
          let data = response.data.data.map(x => x.top_category)
          this.uniqueCategories = [...new Set(data)];
        })
      },

      getData() {
        this.AccountId = this.$route.params.accountId
        this.$store.dispatch('account/getAccountTransaction', {
          account_id: this.$route.params.accountId,
          queryStrings: this.queryStrings
        })
        this.allData()
        this.noFilterData()
      },
      exportToExcel() {
        import('../../../components/excel/ExportExcel').then(excel => {
          const list = this.details
          const data = this.formatJson(this.headerVal, list)
          excel.export_json_to_excel({
            header: this.headerTitle,
            data,
            filename: this.fileName,
            autoWidth: this.cellAutoWidth,
            bookType: this.selectedFormat || 'xlsx'
          })
          this.clearFields()
        })

      },
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => {
          return v[j]
        }))
      },
      clearFields() {
        this.filename = ''
        this.cellAutoWidth = true
        this.selectedFormat = 'xlsx'
      },
      toggleNewTransactionPopup(val = false) {
        this.NewTransactionActive = val
      },
      toggleTransferMoneyPopup(val = false) {
        this.TransferMoney = val
      },
    },
    mounted() {
      this.checkSubCategory()
      this.allData()
    },
    created() {
      Object.keys(this.$route.query).forEach(index => {
        this.$set(this.filter, index, this.$route.query[index])
      })
      this.getData()
      this.$store.dispatch('account/GetAccountData', this.AccountId)
      this.noFilterData()
    }
  }

</script>
<style type="text/css">
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  .cursor {
    cursor: pointer;
  }

</style>
