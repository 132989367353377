<template>
  <div>
    <vs-popup :title="title" :active.sync="popupActive">
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-w="12">
          <vs-input class="mt-5 w-full" :label-placeholder="$t('Name')" v-model="localData.name" />
        </vs-col>
        <vs-col>
          <vs-alert class="w-full mb-5" color="danger" v-show="errors.name" v-if="errors.name" active="true">
            {{ $t(errors.name[0]) }}
          </vs-alert>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-w="12" vs-justify="flex-end">
          <vs-button @click="save">{{$t('Save')}}</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
    name: "ActionsPopup",
    props: ['elementId', 'isCategory','parent'],
    computed: {
      title() {
        if (this.isCategory) {
          return this.elementId ? this.$t('Edit Category') : this.$t('Create Category')
        } else {
          return this.elementId ? this.$t('Update Sub Category') : this.$t('Create Sub Category')
        }
      }
    },
    data() {
      return {
        popupActive: true,
        errors: [],
        localData: {},
        parent_id: null
      }
    },
    watch: {
      popupActive(val) {
        if (!val) {
          setTimeout(() => {
            this.$emit('close-popup')
          }, 1)
        }
      },
    },
    methods: {
      getData() {
        if (this.elementId) {
          this.$store.dispatch("transactionCategory/GetElement", {
            'id': this.elementId
          }).then(() => {
            this.localData = {
              ...this.$store.getters["transactionCategory/element"]
            }
          })
        }
      },
      save() {
        this.parent_id = this.$route.params.id
        if (this.parent_id) {
          this.localData['parent_id'] = this.parent_id
        }
        if(this.parent){
          this.localData['parent_id'] = this.parent
        }
        this.$store.dispatch("transactionCategory/SetElement", {
          'elementData': this.localData
        })
        if (!this.elementId) {
          this.$store.dispatch("transactionCategory/Store").then(() => {
            this.popupActive = false
          }).catch((error) => {
            if (error.response.data.errors)
              this.errors = error.response.data.errors
          })
        } else {
          this.$store.dispatch("transactionCategory/Update", {
            'id': this.elementId
          }).then(() => {
            this.popupActive = false
          }).catch((error) => {
            if (error.response.data.errors)
              this.errors = error.response.data.errors
          })
        }
      }
    },
    created() {
      this.getData()
    }
  }

</script>
